import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
const root = document.getElementById("root");
const rootInstance = ReactDOM.createRoot(root);
rootInstance.render(<App />);

reportWebVitals(sendToVercelAnalytics);
