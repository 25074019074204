import React, { useEffect, useState } from "react";

const MyComponent = () => {
  const [text, setText] = useState({});
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://api.vvhan.com/api/horoscope?type=scorpio&time=today"
      );
      const data = await response.json();
      console.log(data);
      setText(data.data.fortunetext);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {/* Your component JSX */}
      {text?.all}
    </div>
  );
};

export default MyComponent;
